
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'respimgsize tt-product product-parent options-js thumbprod-center product-nohover tt-small' }, _createElement('div', { 'className': 'tt-image-box' }, _createElement('a', {
                    'href': '#',
                    'data-toggle': 'modal',
                    'data-target': '#ModalquickView',
                    'className': 'tt-btn-quickview',
                    'data-firstavavariantid': this.variant_ids[0],
                    'data-value': this.url,
                    'data-quantity': this.variant_ids[0] + '--1;',
                    'data-tooltip': 'Quick View',
                    'data-tposition': 'left'
                }), _createElement('a', {
                    'className': 'tt-img-parent',
                    'href': this.url
                }, _createElement('span', { 'className': 'tt-img' }, _createElement('img', {
                    'className': 'lazyload',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), _createElement('span', { 'className': 'tt-label-location' }, this.on_sale ? _createElement('span', {
                    'className': 'tt-label-sale',
                    'key': '681'
                }, '\n          Sale\n          ', _createElement('span', { 'className': 'thumb_percent' }, '\n            ', Math.round((this.compare_at_price - this.price) * 100 / this.compare_at_price), '%\n          ')) : null))), _createElement('div', { 'className': 'tt-description' }, _createElement('div', { 'className': 'tt-row' }, _createElement('ul', { 'className': 'tt-add-info' }, _createElement('li', {}, _createElement('a', mergeProps({
                    'href': this.vendor_url,
                    'className': 'text-uppercase'
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))))), _createElement('h2', { 'className': 'tt-title prod-thumb-title-color' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'tt-price' }, _createElement('span', { 'className': this.on_sale ? 'new-price' : '' }, this.formatPrice(this.price)), this.on_sale ? _createElement('span', {
                    'className': 'old-price',
                    'key': '1503'
                }, this.formatPrice(this.compare_at_price)) : null), _createElement('div', { 'className': 'tt-product-inside-hover' }, _createElement('div', { 'className': 'tt-row-btn' }, this.variant_ids.length === 1 ? _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'encType': 'multipart/form-data',
                    'data-servicify-form': 'true',
                    'key': '1689'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('button', {
                    'className': 'tt-btn-addtocart thumbprod-button-bg',
                    'disabled': this.out_of_stock
                }, _createElement('span', { 'className': 'icon icon-shopping_basket' }), _createElement('span', {}, this.out_of_stock ? 'SOLD OUT' : 'ADD TO CART'))) : null, this.variant_ids.length > 1 ? _createElement('a', {
                    'className': 'btn',
                    'href': this.url,
                    'key': '2184'
                }, '\n          ', this.out_of_stock ? 'SOLD OUT' : 'SELECT OPTIONS', '\n        ') : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []