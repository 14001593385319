import shopifyGenericDefaults from '../shopify-generic/config.js';

const defaultHandleCurrencies = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchBox',
).onDropdownItemsReceived;

const genericInitFunc = shopifyGenericDefaults.InitFunc;
const InitFunc = () => {
  genericInitFunc?.();
  window.addEventListener('resize', () => {
    if (window.innerWidth < 1025 && document.querySelector('.tt-desctop-parent-search .tt-search.active')) {
      document.querySelector('.tt-desctop-parent-search .tt-btn-close')?.click();
    }
  });
};

const genericUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
).updateCallback;
const updateCallback = () => {
  genericUpdateCallback?.();

  window.viewButtons?.();
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => w.name !== 'SearchResult'),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'DesktopSearchBox',
      type: 'SearchBox',
      location: {
        selector: '.tt-desctop-parent-search',
        class: 'cm-search-box-root__desktop',
      },
      onDropdownItemsReceived: () => defaultHandleCurrencies(),
      template: 'DesktopSearchBox',
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
      onSubmit: () => window.document.querySelector('.cm-search-box-root__desktop .tt-btn-close').click(),
    },
  ],
};
